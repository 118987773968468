import React from "react"
import { Link } from "gatsby";
import { graphql } from 'gatsby';
import '../scss/index.scss';
import Layout from "../components/layout"
import SEO from "../components/seo";
import BackgroundSlider from 'react-background-slider';
import CookieConcent from '../components/cookieConcent';

const imageArray = (images) => {

  const array = images.map(edge => {
    return edge.node.img.fluid.src;
  });

  return array;
};



const IndexPage = (props) => {

  return (
    <Layout>
    <SEO title="Home" keywords={[`photographer`, `fotograf`, `dusseldorf`, `düsseldorf`, `germany`, `写真家`, `portrait`, `porträt`, `カメラマン`, `TFP`, `landscape`, `hobby`, `japanese`, `street`, `outdoor`]} />
    <div className="home-wrapper">
      <h1 className="home-wrapper_title">Takuro Yakubo</h1>
      <h2>Photographer "currently" based in Düsseldorf, Germany</h2>
      <div className="home-wrapper_links">
        <Link to="/portfolio/">Portfolio</Link>
        <a href="https://www.behance.net/takuroyakubo" target='_blank' rel="noreferrer">Behance</a>
        <a href="https://www.instagram.com/takk.eats.world/" target='_blank' rel="noreferrer">Instagram</a>
        <Link to="/contact/">Contact</Link>
      </div>
    </div>

    <BackgroundSlider
      images={imageArray(props.data.allContentfulBackground.edges)}
      duration={5} transition={2} />

    <CookieConcent />
  </Layout>
  )
};

export default IndexPage;

export const query = graphql` 
query IndexQuery {
  allContentfulBackground(
      sort: { fields: [createdAt], order: DESC }
    ) {
    edges {
      node {
        id
        img {
          fluid(quality: 95) {
            src
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
}
`
